import logo from "./assets/logo.svg";
import CardStack from "./components/CardStack";

function App() {
  return (
    <main className="bg-blue text-white h-screen w-screen text-center relative">
      <header className="w-full flex items-center justify-between absolute top-0 p-5">
        <img src={logo} className="w-6 text-white" alt="logo" />
      </header>
      <CardStack />
    </main>
  );
}

export default App;
