import { QuestionContentProps } from "../types/question";

export const generalQuestions: QuestionContentProps[] = [
  { id: 17, text: "What has been the biggest learning curve in your career?" },
  {
    id: 18,
    text: "What's the biggest reaction someone has had to your work?",
    author: "Episode 151",
    episodeLink:
      "https://creators.spotify.com/pod/show/australian-design-radio/episodes/Ep151-Richie-Meldrum--Paul-Balfe--Kiel-Tillman-Pennybridge--TDC24-e2ojger/a-abhll4h",
  },
  {
    id: 2,
    text: "What is rewarding right now about your work or creative practice?",
  },
  { id: 6, text: "What made you want to work in the creative industry?" },
  {
    id: 3,
    text: "What's a useful skill you've learned from experience, that a course or degree won't teach you?",
  },
  { id: 4, text: "Where do you feel most creative?" },
  { id: 5, text: "What do you hope to do more of?" },
  {
    id: 27,
    text: "What song best encapsulates your creative practice and why?",
    author: "Nu Goteh",
    episodeLink:
      "https://creators.spotify.com/pod/show/australian-design-radio/episodes/Ep-160-Nu-Goteh-Room-For-Magic--Deem-Journal--Anzac-Tasker-Guardians-e2r9ub3/a-abl2saa",
  },
  { id: 8, text: "What has been the most challenging project to work on?" },
  {
    id: 10,
    text: "What is a challenge right now in your work or creative practice?",
  },
  { id: 12, text: "What is your design pet-peeve?" },
  {
    id: 13,
    text: "What do you want your legacy or impact to be?",
    author: "Kel Lauren, Ep 149",
    episodeLink:
      "https://creators.spotify.com/pod/show/australian-design-radio/episodes/Ep149-Kel-Lauren--Elliot-Ulm-e2nfqe4/a-abg8erv",
  },
  {
    id: 14,
    text: "What would you like to see change in the creative industry?",
  },
  { id: 15, text: "What have you been experimenting with recently?" },
  {
    id: 16,
    text: "What has been the most fulfilling project you've worked on?",
  },
  { id: 19, text: "What's your unpopular design opinion?" },
  {
    id: 20,
    text: "Who do you look up to, and how have they influenced your work?",
  },
  { id: 21, text: "What do you want to do less of?" },
  {
    id: 22,
    text: "Do you solve creative problems while you sleep?",
    author: "Carmen Angelillo, Ep152",
    episodeLink:
      "https://creators.spotify.com/pod/show/australian-design-radio/episodes/Ep152-Carmen-Angelillo-Niceshit--Mat-Voyce--TDC24-e2osvc8/a-abi3fa3",
  },
  { id: 23, text: "How did you get into this industry?" },
  { id: 24, text: "What's your go-to design tool?" },
  { id: 25, text: "When in the day are you most creative?" },
  { id: 26, text: "What is a project that you're truly proud of and why?" },

  {
    id: 28,
    text: "In the future, what do you want design to feel like?",
    author: "Nu Goteh",
    episodeLink:
      "https://creators.spotify.com/pod/show/australian-design-radio/episodes/Ep-160-Nu-Goteh-Room-For-Magic--Deem-Journal--Anzac-Tasker-Guardians-e2r9ub3/a-abl2saa",
  },
];
