import createStyle from "@josephmark/createstyle";

export const BodyXSmall = createStyle(
  "p",
  "text-sans font-medium text-[0.75rem] leading-[1rem]"
); // 12px, 18px
export const BodySmall = createStyle(
  "p",
  "text-sans font-medium text-[0.875rem]"
); // 14px, 20px
export const BodyMedium = createStyle(
  "p",
  "text-sans text-[1.25rem] leading-[1.625rem]"
); // 24px, 26px
export const BodyLarge = createStyle(
  "p",
  "text-sans font-medium text-[1.4rem] sm:text-[1.625rem] leading-[1.8rem]"
); // 26px, 36px
