import { useState } from "react";
import {
  motion,
  AnimatePresence,
  useTransform,
  useMotionValue,
} from "framer-motion";
import { BodySmall, BodyLarge, BodyXSmall, BodyMedium } from "../../text";
import clsx from "clsx";
import { generalQuestions } from "../../constants/questions";
import { QuestionContentProps } from "../../types/question";

const VELOCITY_THRESHOLD = 50;
const OFFSET_THRESHOLD = 50;

export default function CardStack() {
  const [cards, setCards] = useState<QuestionContentProps[]>(generalQuestions);
  const [swipeDirection, setSwipeDirection] = useState<"left" | "right">(
    "left"
  );
  const x = useMotionValue(0);
  const rotateValue = useTransform(x, [-1000, 0, 0, 1000], [-8, 0, 0, 8]);

  /// Removes the top card
  const removeCard = () => {
    x.set(0);
    setCards((prev) => prev.slice(1));
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center pt-20 sm:pt-12">
      <div
        className="relative w-[18rem] sm:w-80 h-[28rem] sm:h-[31rem] flex flex-col items-center justify-between"
        style={cards.length <= 0 ? { height: "auto" } : {}}
      >
        <AnimatePresence>
          {cards.map((card, index) => (
            <motion.div
              className={clsx(
                "absolute w-[18rem] h-[28rem] sm:h-[31rem] sm:w-80 rounded-3xl ",
                "border p-3 text-blue bg-white",
                "overflow-y-auto hover:cursor-pointer"
              )}
              style={{
                zIndex: cards.length - index,
                rotate: index === 0 ? rotateValue : 0,
              }}
              key={card.id}
              drag="x"
              dragConstraints={{ left: -20, right: 20 }}
              onDrag={(event, info) => {
                if (index === 0) {
                  x.set(info.offset.x);
                }
                if (info.offset.x > OFFSET_THRESHOLD) {
                  setSwipeDirection("right");
                } else if (info.offset.x < -OFFSET_THRESHOLD) {
                  setSwipeDirection("left");
                }
              }}
              whileDrag={{ scale: 0.95 }}
              onDragEnd={(_, info) => {
                if (
                  (info.offset.x > OFFSET_THRESHOLD ||
                    info.offset.x < -OFFSET_THRESHOLD) &&
                  (info.velocity.x > VELOCITY_THRESHOLD ||
                    info.velocity.x < -VELOCITY_THRESHOLD)
                ) {
                  removeCard();
                }
              }}
              animate={{
                scale: index === 0 ? 1.06 : 1,
                y: index === 0 ? -26 : 0,
              }}
              exit={{
                opacity: 0,
                x:
                  swipeDirection === "right"
                    ? 500
                    : swipeDirection === "left"
                    ? -500
                    : 0,
              }}
              transition={{ type: "tween", stiffness: 500, damping: 50 }}
            >
              <div className="w-full h-full rounded-2xl border bg-white text-blue p-4 flex flex-col justify-between">
                <BodyXSmall className="font-normal">ADR+CONVOS</BodyXSmall>
                <div>
                  <BodyLarge>{card.text}</BodyLarge>
                  {card.author && (
                    <BodySmall className="mt-8">{card.author}</BodySmall>
                  )}
                </div>
                {card.episodeLink ? (
                  <a
                    className="bg-orange text-white rounded-2xl text-xs inline-block p-1 px-3 w-fit mx-auto"
                    href={card.episodeLink}
                    target="_blank"
                  >
                    Listen to episode
                  </a>
                ) : (
                  <BodyXSmall className="font-normal">
                    by Australian Design Radio
                  </BodyXSmall>
                )}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {cards.length > 0 && (
        <button
          onClick={() => {
            setSwipeDirection(Math.random() > 0.5 ? "left" : "right");
            removeCard();
          }}
          className="mt-4 border border-white cursor-pointer rounded-full px-4 py-1.5 tracking-wider font-medium text-[0.875rem]"
        >
          Next
        </button>
      )}

      {cards.length === 0 && (
        <div className="max-w-sm p-4">
          <BodyLarge className="mb-2">That's all folks!</BodyLarge>
          <BodyMedium className="mb-6">
            You sped through these convos. Click 'reset' to view them again.
          </BodyMedium>
          <button
            onClick={() => setCards(generalQuestions)}
            className="border border-white cursor-pointer rounded-full px-4 py-1.5 tracking-wider font-medium text-[0.875rem]"
          >
            Reset
          </button>
        </div>
      )}
    </div>
  );
}
